:root {
  --bg-shade1: #fff;
  --bg-shade2: #def0ff;
  --bg-shade3: #bfddff;
  --bs-body-font-family: "Montserrat", sans-serif;
}

body {
  background: #ccc;
}

a {
  text-decoration: none;
}

.navbar-brand > img {
  height: 48px;
}

.navbar-nav > .nav-item {
  text-transform: uppercase;
}

.navbar-expand-xl .navbar-nav .nav-link {
  color: #fff;
  border-bottom: 1px solid #0000;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  transition: border-color .5s;
}

.navbar-nav .nav-link.active, .navbar-expand-xl .navbar-nav .nav-link:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.bg-shade1 {
  background-color: var(--bg-shade1);
}

.bg-shade2 {
  background-color: var(--bg-shade2);
}

.bg-shade3 {
  background-color: #bfddff;
}

.bg-shade3 a {
  color: #000;
}

footer {
  background-color: #85bfff;
}

video {
  object-fit: cover;
  z-index: -1;
  height: 100%;
}

.app-header-text {
  min-height: 500px;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

.app-quote {
  border: 1px solid var(--bg-shade1);
}

.app-quote-quotes-left {
  background-color: var(--bg-shade3);
  color: var(--bg-shade1);
  top: calc(-1rem - 12px);
  left: calc(-1rem - 20px);
}

.app-quote-quotes-right {
  background-color: var(--bg-shade3);
  color: var(--bg-shade1);
  bottom: calc(-1rem - 12px);
  right: calc(-1rem - 20px);
}

.app-trapezoid {
  z-index: -1;
  inset: 0;
}

.app-bgblock-left {
  z-index: -1;
  width: 100%;
  top: 0;
  bottom: 0;
}

.app-bgblock-right {
  z-index: -1;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
}

@media (width >= 992px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .app-header-text-body {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    width: 45%;
  }

  .app-header-text-body-videovastaanotto {
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    width: 55%;
    position: relative;
    padding-right: 5rem !important;
  }

  .app-trapezoid {
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 85%);
    z-index: -1;
    inset: 0;
  }

  .app-header-text-body-etatutkimuslaitteet {
    max-width: 40%;
  }

  .app-bgblock-left {
    z-index: -1;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    width: 80%;
    top: 0;
    bottom: 0;
  }

  .app-bgblock-left-text {
    width: 70%;
    padding-right: 20% !important;
  }

  .app-bgblock-right {
    z-index: -1;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 80%;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .app-bgblock-right-text {
    width: 70%;
    padding-left: 20% !important;
  }

  .app-contact-left {
    max-width: 40%;
  }

  .app-contact-right {
    width: 60%;
    max-width: 60%;
  }

  .app-contact-right > .card {
    width: 60%;
  }
}

/*# sourceMappingURL=index.6d8b452a.css.map */
