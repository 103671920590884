:root {
  --bg-shade1: #fff;
  --bg-shade2: #def0ff;
  --bg-shade3: #bfddff;
  --bs-body-font-family: 'Montserrat', sans-serif;
}

body {
  background: #ccc;
}

/*
@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
*/

a {
  text-decoration: none;
}

.navbar-brand > img {
  height: 48px;
}

.navbar-nav > .nav-item {
  text-transform: uppercase;
}

.navbar-expand-xl .navbar-nav .nav-link {
  border-bottom: 1px solid transparent;
  color: #fff;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  transition: border-color .5s ease;
}

.navbar-nav .nav-link.active,
.navbar-expand-xl .navbar-nav .nav-link:hover {
  border-bottom: 1px solid #fff;
  color: #fff;
}

.bg-shade1 {
  background-color: var(--bg-shade1);
}

.bg-shade2 {
  background-color: var(--bg-shade2);
}

.bg-shade3 {
  background-color: #bfddff;
}

.bg-shade3 a {
  color: #000;
}

footer {
  background-color: #85bfff;
}

video {
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.app-header-text {
  min-height: 500px;
}

.app-header-text-body {
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

.app-quote {
  border: 1px solid var(--bg-shade1);
}

.app-quote-quotes-left {
  background-color: var(--bg-shade3);
  color: var(--bg-shade1);
  left: calc(-1rem - 40px / 2);
  top: calc(-1rem - 24px / 2);
}

.app-quote-quotes-right {
  background-color: var(--bg-shade3);
  bottom: calc(-1rem - 24px / 2);
  color: var(--bg-shade1);
  right: calc(-1rem - 40px / 2);
}

.app-trapezoid {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

.app-bgblock-left {
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

    .app-bgblock-right {
      bottom: 0;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1;
    }
@media (min-width: 992px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .app-header-text-body {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    width: 45%;
  }

  .app-header-text-body-videovastaanotto {
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    padding-right: 5rem !important;
    position: relative;
    width: 55%;
  }

  .app-trapezoid {
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 85%);
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .app-header-text-body-etatutkimuslaitteet {
    max-width: 40%;
  }

  .app-bgblock-left {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    bottom: 0;
    top: 0;
    width: 80%;
    z-index: -1;
  }

  .app-bgblock-left-text {
    padding-right: 20% !important;
    width: 70%;
  }

  .app-bgblock-right {
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
    bottom: 0;
    right: 0;
    top: 0;
    width: 80%;
    z-index: -1;
  }

  .app-bgblock-right-text {
    padding-left: 20% !important;
    width: 70%;
  }

  .app-contact-left {
    max-width: 40%;
  }

  .app-contact-right {
    max-width: 60%;
    width: 60%;
  }

  .app-contact-right > .card {
    width: 60%;
  }
}
